import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { iterate } from "localforage";
import {
    DataGridTooltipMessageItem
} from "@app/shared/common/data-grid/data-grid-tooltip-message/data-grid-tooltip-message.models";
import {Moment} from "@node_modules/moment";


@Component({
    selector: 'app-data-grid-tooltip-message',
    templateUrl: './data-grid-tooltip-message.component.html',
    styleUrls: ['./data-grid-tooltip-message.component.css']
})
export class DataGridTooltipMessageComponent {
    @Output() onCellButtonClick = new EventEmitter<string>();
    items: DataGridTooltipMessageItem[] = [];

    setData(data: DataGridTooltipMessageItem[]) {
        this.items = data;
    }

    onButtonClick(key:string){
        this.onCellButtonClick.emit(key);
    }

}
