import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from '@node_modules/ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    CellLockDto, CellLockRequestDto,
    CellLockServiceProxy,
    CompaniesServiceProxy, DomainServiceProxy, InputMasterType,
    Lookup
} from '@shared/service-proxies/service-proxies';
import * as moment from '@node_modules/moment';

@Component({
    selector: 'cell-lock-form',
    templateUrl: './cell-lock.component.html',
})
export class CellLockModalComponent extends AppComponentBase implements OnInit {
    @ViewChild('cellLockModal', { static: true }) modal: ModalDirective;

    @Output() modalSave: EventEmitter<number[]> = new EventEmitter<number[]>();
    companies: Lookup[];
    companiesForList: Lookup[];
    saving = false;
    headerText: string;
    saveButtonText: string;
    saveButtonIcon: string;
    cellLockDto: CellLockDto = new CellLockDto();
    allSelected = false;

    constructor(
        private injector: Injector,
    private readonly _domainServiceProxy: DomainServiceProxy,
    private readonly _companyServiceProxy: CompaniesServiceProxy,
    private readonly _cellLockServiceProxy: CellLockServiceProxy
    ) {
        super(injector);
    }

    show(_type: InputMasterType, _forLock: boolean, _operationDate: moment.Moment): void {
        this.cellLockDto.inputMasterType = _type;
        this.cellLockDto.operationDate = _operationDate;
        this.cellLockDto.isLocked = _forLock;
        if (_forLock){
            this.headerText = 'SelectForLock';
            this.saveButtonText = 'Lock';
            this.saveButtonIcon = 'fa-lock';
        }else{
            this.headerText = 'SelectForUnlock';
            this.saveButtonText = 'Unlock';
            this.saveButtonIcon = 'fa-unlock';
        }
        this.cellLockDto.companyIds = [];
        this.registerLockedCompanies();

        this.modal.show();
    }

    ngOnInit() {
        this.cellLockDto.companyIds = [];
        super.ngOnInit();
        this.registerCompanies();
    }


    save(): void {
        this._cellLockServiceProxy.createOrDelete(this.cellLockDto)
            .pipe()
            .subscribe(({}) => {
                abp.message.success('Completed', this.saveButtonText);
                this.modalSave.emit(this.cellLockDto.companyIds);
                this.close();
                this.allSelected =false;

            });
    }

    close(): void {
        this.modal.hide();
    }
    registerLockedCompanies() {


        const request = new CellLockRequestDto({
            inputMasterType: this.cellLockDto.inputMasterType,
            operationDate: this.cellLockDto.operationDate,
            startDate:null,
            endDate:null
        });
        this._cellLockServiceProxy.lockedCompanies(request).pipe().subscribe({
            next: dto => {
                let companyIds = dto.length > 0 ? dto[0].companyIds : [];

                this.companiesForList = this.companies.filter(s => {
                    if (this.cellLockDto.isLocked) {
                        return companyIds.indexOf(s.id) < 0;
                    } else {
                        return companyIds.indexOf(s.id) >= 0;
                    }
                });

                if (this.companiesForList.length > 0) {
                    this.companiesForList.push(new Lookup({ id: 0, name: this.l('SelectAll') }));
                    this.moveLastItemToFirst(this.companiesForList);
                }
            }
        });
    }

    moveLastItemToFirst(arr: any[]): any[] {
        const lastItem = arr.splice(-1, 1)[0];
        arr.splice(0, 0, lastItem);
        return arr;
    }

    toggleSelectAll(e): void {
        if(e.some(value => value === 0)) {
            this.cellLockDto.companyIds = this.companiesForList.filter(p=>p.id!=0).map(company => company.id);
        }
    }

    registerCompanies(){
        this._domainServiceProxy.getLookupForPermittedCompanies().pipe()
            .subscribe({
                next: items => {
                    this.companies = items;
                }
            });
    }
}

