import { Moment } from "moment/moment";
import { Lookup, VersionDto } from "@shared/service-proxies/service-proxies";

export interface NewVersionCreatedEvent {
    read: boolean;
    customerId: number;
    operationDate: Moment;
    createdAt: Moment;
    company: Lookup;
    version: VersionDto;
    source: VersionCreatedEventSource
    extraProperties: Record<string, any>;
}

export enum VersionCreatedEventSource {
    Customer = 1,
    Dispatcher = 2,
}
