import { Injectable, Injector, NgZone } from "@angular/core";
import { AppComponentBase } from "@shared/common/app-component-base";
import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@node_modules/@microsoft/signalr";
import { RealTimeCollectionProviderModel } from "@app/shared/core/real-time/real-time.model";
import * as moment from "@node_modules/moment/moment";
import { AbpSessionService } from "abp-ng2-module";
import { LocalStorageService } from "@shared/utils/local-storage.service";
import { AppConsts } from "@shared/AppConsts";

@Injectable({ providedIn: 'root' })
export class CustomerSignalrService extends AppComponentBase implements RealTimeCollectionProviderModel {
    commonHub: HubConnection;

    static customCustomerEvents = [
        "flexiblePositionVersionCreated",
        "consumptionForecastVersionCreated",
        "netBilateralContractManagementVersionCreated",
        "bilateralContractCreated",
        "dayAheadMarketManagementVersionCreated",
    ];

    constructor(
        injector: Injector,
        public _zone: NgZone,
        private session: AbpSessionService,
        private localStorageService: LocalStorageService
    ) {
        super(injector);
    }

    init(): void {
        this.localStorageService.getItem(AppConsts.authorization.encrptedAuthTokenName, (err, value) => {
            let encryptedAuthToken = value?.token;

            const basePath = AppConsts.remoteServiceWsUrl;
            const url = `${basePath}?${AppConsts.authorization.encrptedAuthTokenName}=${encodeURIComponent(encryptedAuthToken)}`;

            this.commonHub = new HubConnectionBuilder()
                .withUrl(url, {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,
                })
                .withAutomaticReconnect()
                .build();

            this.commonHub
                .start()
                .then(function () {
                    console.log('SignalR Customer Hub Connected!');
                })
                .catch(function (err) {
                    return console.error(err.toString());
                });

            this.registerCustomEvents();
        });

    }

    private registerCustomEvents() {
        for (let versionCreatedEventName of CustomerSignalrService.customCustomerEvents) {
            this.commonHub.on(`${this.session.tenantId}-${versionCreatedEventName}`, (message) => {
                if ('createdAt' in message) message.createdAt = moment(message.createdAt);
                if ('operationDate' in message) message.operationDate = moment(message.operationDate);
                abp.event.trigger(`abp.customer.${versionCreatedEventName}`, message);
            });
        }
    }

}
