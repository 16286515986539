import { PowerPlantUevcbItem } from '@app/shared/common/company-uevcb-list/PowerPlantUevcbItem';

export  class CompanyUevcb{
    id: number;
    name: string;
    shortName: string;
    powerPlants: PowerPlantUevcbItem[];
    constructor(organizationName: string, organizationShortName: string, organizationCode: string) {
        this.name = organizationName;
        this.shortName = organizationShortName;
    }
}
