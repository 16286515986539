<a href="/" target="_blank">
    <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" alt="Logo" [src]="defaultLogo" [class]="imageClass" />
    <img
        *ngIf="appSession.tenant && appSession.tenant.logoId"
        [src]="
            remoteServiceBaseUrl +
            '/TenantCustomization/GetTenantLogo?skin=' +
            currentTheme.baseSettings.menu.asideSkin +
            '&tenantId=' +
            appSession.tenant.id +
            '&id=' +
            appSession.tenant.logoId
        "
        alt="Logo"
        [class]="imageClass"
    />
</a>
