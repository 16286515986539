<mat-form-field>
    <mat-label>Choose a date</mat-label>
  <input
    matInput
    autocomplete="off"
    class="form-control"
    type="text"
    [disabled]="Disabled"
    (ngModelChange)="onDateChange($event)"
    [(ngModel)]="_selectedDate"
    [id]="Id"
    [name]="Id"
    [required]="IsRequired"
    [matDatepicker]="picker"
  >
  <mat-hint>MM/DD/YYYY</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

