<div
    appBsModal
    #cellLockModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createOrEditModal"
    aria-hidden="true"
    [config]="{ backdrop: 'static', keyboard: !saving }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #roleForm="ngForm" novalidate (ngSubmit)="save()">
                <div class="modal-header">
                    <h4 class="modal-title">

                    </h4>
                    <button
                        type="button"
                        class="btn-close"
                        (click)="close()"
                        [attr.aria-label]="l('Close')"
                        [disabled]="saving"
                    >
                    </button>
                </div>
                <div class="modal-body">
                    <tabset>
                        <tab class="pt-5" heading="{{ this.headerText }}">
                            <div class="mb-5">
                                <label class="form-label">{{ 'Companies' | localize }} *</label>
                                <ng-select id="companies" [items]="companiesForList" bindLabel="name" bindValue="id"
                                           [(ngModel)]="cellLockDto.companyIds" name="companies" [closeOnSelect]="false"
                                           [multiple]="true" (ngModelChange)="toggleSelectAll($event)">
                                </ng-select>
                            </div>
                        </tab>
                    </tabset>

                </div>
                <div class="modal-footer">
                    <button
                        [disabled]="saving"
                        type="button"
                        class="btn btn-light-primary fw-bold"
                        (click)="close()"
                    >
                        {{ 'Cancel' | localize }}
                    </button>
                    <button
                        type="submit"
                        class="btn btn-primary fw-bold"
                        [disabled]="!roleForm.form.valid"
                        [buttonBusy]="saving"
                        [busyText]="l('SavingWithThreeDot')"
                    >
                        <i class="fa {{this.saveButtonIcon}}"></i>
                        <span>{{ this.saveButtonText }}</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
