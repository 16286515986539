import {CreatePowerPlantGenerationProvidersDto} from "@shared/service-proxies/service-proxies";

export class PowerPlantUevcbItem{
     eic: string;
     name: string;
     installedPower: number;
     selected: boolean;
     shortName: string;
     type = 20;
     typeName:string;
     uevcbId:number;
     id: number;
     providers:CreatePowerPlantGenerationProvidersDto[] = [];
    constructor(eic: string, name: string, uevcbId:number, shortName?:string, powerPlantId?:number) {
        this.eic = eic;
        this.name = name;
        this.installedPower = 0;
        this.selected = false;
        this.uevcbId = uevcbId;
        this.shortName = '';
        this.shortName = shortName;
        this.id = powerPlantId
    }
}
