<div
    appBsModal
    #uevcbListModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="uevcbListModal"
    aria-hidden="true"
    [config]="{ backdrop: 'static', keyboard: !saving }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">
                    </h4>
                    <button
                        type="button"
                        class="btn-close"
                        (click)="close()"
                        [attr.aria-label]="l('Close')"
                        [disabled]="saving"
                    >
                    </button>
                </div>
                <div class="modal-body">

                    <proxy-table #proxyTable [style.display]="loading ? 'none' : 'block'"
                                 [rowDataSchema]="tableDataSchemaRow"
                                 [colDataSchema]="tableDataSchemaCol"
                                 [data]="hotData"
                                 [settings]="settings">
                    </proxy-table>
                </div>
                <div class="modal-footer">
                    <button
                        [disabled]="saving"
                        type="button"
                        class="btn btn-light-primary fw-bold"
                        (click)="close()"
                    >
                        {{ 'Cancel' | localize }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary fw-bold"
                        [disabled]="shouldDisableSaveButton()"
                        [buttonBusy]="saving"
                        [busyText]="l('SavingWithThreeDot')"
                        (click)="save()"
                    >
                        <i class="fa fa-save"></i>
                        <span>{{ 'Save' | localize }}</span>
                    </button>
                </div>
        </div>
    </div>
</div>
