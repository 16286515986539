import { Component, EventEmitter, Input, Output } from "@angular/core";
import * as moment from "@node_modules/moment-timezone";
@Component({
  selector: "app-common-datepicker",
  templateUrl: "./common-datepicker.component.html",
  styleUrls: ["./common-datepicker.component.less"],
})
export class CommonDatepickerComponent {
  constructor() {
  }

  _selectedDate: Date;

  @Input()
  set Monthly(param: boolean) {
    if (param == true) {
      // this.bsConfig['minMode'] = 'month';
      // this.bsConfig['dateInputFormat'] = 'YYYY-MM';
    }
  }

  @Input()
  Disabled: boolean;

  @Input()
  Clearable: boolean;

  @Input()
  LabelToLocalize: string;

  @Input()
  set Date(value: moment.Moment) {
    if (value) {
      this._selectedDate = value.toDate();
    }
  }

  get Date() {
    return moment(this._selectedDate);
  }

  @Output()
  DateChange = new EventEmitter<moment.Moment>();
  @Input()
  Id = undefined;
  @Input()
  MinDate: Date | undefined;
  @Input()
  MaxDate: Date | undefined;


  @Input()
  IsRequired = false;

  ngOnInit() {
  }

  onDateChange($event: Date) {
    if ($event) {
      this.Date = moment($event).startOf('day');
      this.DateChange.emit(this.Date);
    }
  }

  onClearClicked() {
    this._selectedDate = undefined;
    this.Date = undefined;
    this.DateChange.emit(undefined);
  }

}
