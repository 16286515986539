import {Pipe, PipeTransform} from '@angular/core';
import {LocalizePipe} from '@shared/common/pipes/localize.pipe';

export interface EnumItem {
    id: number;
    displayName: string;
    key: string;
    disabled: boolean;
}

@Pipe({
    name: 'enumMembers'
})
export class EnumMembersLocalizedPipe implements PipeTransform {
    constructor(private pipe: LocalizePipe) {
    }

    transform(value: any, localizePrefix?: string): EnumItem[] {
        if (!value) {
            return [];
        }
        localizePrefix = localizePrefix || '';
        return this.enumToArray(value, localizePrefix);
    }

    private enumToArray(Enum: any, localizePrefix: string): EnumItem[] {
        let result =  Object.keys(Enum)
            .filter(k => typeof Enum[k as any] === 'number')
            .map(key => ({
                id: Enum[key],
                key: key,
                disabled: false,
                displayName: this.pipe.transform(localizePrefix + key),
                groupName: ''
            } as EnumItem));
        return result;
    }
}
