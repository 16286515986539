<table>
    <tbody>
    <tr *ngFor="let item of items">
        <td style="font-weight: 500">{{ item.key }}: </td>
        <td>{{ item.value }}
            <button *ngIf="item.buttonText || item.buttonIconClass"
                                     (click)="onButtonClick(item.key)" class="btn btn-primary btn-sm">
            <i *ngIf="item.buttonIconClass" class="{{item.buttonIconClass}}" ></i> {{item.buttonText}}
            </button>
        </td>
    </tr>
    </tbody>
</table>
