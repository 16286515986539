import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'sticky-buttons',
    templateUrl: './sticky-buttons.component.html',
    styleUrls: ['./sticky-buttons.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StickyButtonsComponent {

    @Input() sourceInfoElemId = '';
    @Input() operationResultsElemId = '';
    @Input() logsDestinationElemId = '';
    @Input() numberOfLogs: number;

    @Output() showLogsClick = new EventEmitter();

    onShowLogButtonClick() {
        this.showLogsClick.emit();
    }
}
