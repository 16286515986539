import * as moment from 'moment';

export abstract class Base<T> {
    setData(data: any): T {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
        return <any>this;
    }

    set<K extends keyof T>(key: K, value: any): T {
        (<any>this)[key] = value;
        return <any>this;
    }
}

export class BasicDataSchema extends Base<BasicDataSchema> implements IBasicDataSchema {

    constructor(headerName: string) {
        super();
        this.HeaderName = headerName;
    }

    HeaderName: string;
    HeaderHtml?: string | undefined;
    HeaderBackgroundColor: string;
    HeaderFontWeight: string;
    HeaderFontColor?: string;
}

export class DataSchema extends Base<DataSchema> implements IDataSchema {

    constructor(headerName?: string, dataName?: string | ((rowData: any, value: any) => any), dataType?: DataTypes, readOnly?: boolean, powerPlantId?:number,dataProvider?:string) {
        super();
        this.HeaderName = headerName;
        this.DataName = dataName;
        this.ReadOnly = readOnly;
        this.DataType = dataType;
        this.PowerPlantId = powerPlantId;
        this.DataProvider = dataProvider;
    }

    HeaderName: string;
    HeaderHtml?: string | undefined;
    DataName: string | ((rowData: any, value: any) => any);
    ReadOnly: boolean;
    HeaderBackgroundColor?: string | undefined;
    HeaderFontWeight?: string | undefined;
    DataType?: DataTypes | undefined;
    Conditions?: DataConditions[] | undefined;
    ConditionOption?: IConditionOptions | undefined;
    ConditionInfo?: IConditionInfo[] | undefined;
    DisableValidation?: boolean | undefined;
    TypeOption?: ITypeOptions | undefined;
    CustomRenderer?: any | undefined;
    AllowEmpty?: boolean | undefined;
    ReferanceData?: any | undefined;
    HeaderGroup?: IGroupDataSchema | undefined;
    ClassName?: string | string[] | undefined;
    PowerPlantId?: number | undefined;
    DataProvider?: string | undefined;
}

export class TypeOptions extends Base<TypeOptions> implements ITypeOptions {
    Source?: string[] | number[] | ((query: string, callback: (items: string[]) => void) => void) | undefined;
    TimeFormat?: string | undefined;
    NumericFormat?: string | undefined;
    DateFormat?: string | undefined;
    CorrectInputFormat?: boolean | undefined;
    DefaultDate?: moment.Moment | undefined;
}

export class ConditionOptions extends Base<ConditionOptions> implements IConditionOptions {
    MinNumber?: number | MinMaxNumberGetter | number[] | MinMaxNumberGetter[] | any[] | undefined;
    MaxNumber?: number | MinMaxNumberGetter | number[] | MinMaxNumberGetter[] | any[] | undefined;
    CustomValidator?: CustomValidatorGetter | CustomValidatorGetter[] | undefined;
}

export interface IBasicDataSchema {
    HeaderName: string;
    HeaderHtml?: string | undefined;
    HeaderBackgroundColor?: string | undefined;
    HeaderFontWeight?: string | undefined;
    HeaderFontColor?: string | undefined;
}

export class GroupDataSchema extends BasicDataSchema implements IGroupDataSchema {
    GroupName?: string | undefined;
}

export interface IGroupDataSchema extends IBasicDataSchema {
    GroupName?: string | undefined;
    HeaderGroup?: IGroupDataSchema |undefined;
}

export interface IDataSchema extends IBasicDataSchema {
    DataName: string | ((rowData: any, value: any) => any);
    ReadOnly: boolean;
    DataType?: DataTypes | undefined;
    Conditions?: DataConditions[] | undefined;
    ConditionOption?: IConditionOptions | undefined;
    ConditionInfo?: IConditionInfo[] | undefined;
    DisableValidation?: boolean | undefined;
    TypeOption?: ITypeOptions | undefined;
    CustomRenderer?: any | undefined;
    AllowEmpty?: boolean | undefined;
    ReferanceData?: any | undefined;
    HeaderGroup?: IGroupDataSchema | undefined;
    ColumnWidth?: any | undefined;
    ClassName?: string | string[] | undefined;
    PowerPlantId?: number | undefined;
    DataProvider?: string | undefined;
}

export interface ITypeOptions {
    Source?: string[] | number[] | ((query: string, callback: (items: string[]) => void) => void) | undefined;
    TimeFormat?: string | undefined;
    NumericFormat?: string | undefined;
    DateFormat?: string | undefined;
    CorrectInputFormat?: boolean | undefined;
    DefaultDate?: moment.Moment | undefined;
}


export type MinMaxNumberGetter = (col: number, row: number, prop: string | number, value: any | undefined) => number | undefined;

export type CustomValidatorGetter = (col: number, row: number, prop: string | number, value: any | undefined) => boolean | undefined;

export interface IConditionOptions {
    MinNumber?: number | MinMaxNumberGetter | number[] | MinMaxNumberGetter[] | any[] | undefined;
    MaxNumber?: number | MinMaxNumberGetter | number[] | MinMaxNumberGetter[] | any[] | undefined;
    CustomValidator?: CustomValidatorGetter | CustomValidatorGetter[] | undefined;
}

export class ConditionClassName extends Base<ConditionClassName> implements IConditionInfo {
    Condition: DataConditions;
    Name: string;
    ClassName: string;
    OptionIndex?: number | undefined;
}

export interface IConditionInfo {
    Condition: DataConditions;
    Name: string;
    ClassName: string;
    OptionIndex?: number | undefined;
    MessageType?: 'info' | 'success' | 'warn' | 'error' | 'confirm' | 'none';
}

export enum DataTypes {
    Number = 'numeric',
    Text = 'text',
    Date = 'date',
    Html = 'html',
    Time = 'time',
    Checkbox = 'checkbox',
    Dropdown = 'dropdown',
    Autocomplete = 'autocomplete'
}

export enum DataConditions {
    NotEmpty,
    OnlyPositive,
    OnlyNegative,
    MaxNumber,
    MinNumber,
    NonZero,
    MustNumeric,
    CustomValidator
}
