import { ErrorHandler, Injectable } from "@angular/core";
import { UserFriendlyError } from "@app/shared/core/errors/user-friendly.error";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    handleError(error: any): void {
        if (error instanceof UserFriendlyError) {
            abp.message.error(error.message);
            return;
        }

        throw error;
    }
}
