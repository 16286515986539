<link rel="stylesheet" type="text/css" href="sticky-buttons.component.css"/>
<div class="app-engage " id="kt_app_engage">
    <div id="{{sourceInfoElemId}}" *ngIf="sourceInfoElemId">
        <button class="app-engage-btn btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="left" title="{{ 'SourceInfo' | localize}}">
            <i class="flaticon-info fs-1 pt-1 mb-2"><span class="path1"></span><span class="path2"></span></i>
        </button>
    </div>
    <div id="{{operationResultsElemId}}" *ngIf="operationResultsElemId">
        <button class="app-engage-btn btn btn-warning" data-bs-toggle="tooltip" data-bs-placement="left" title="{{ 'OperationResults' | localize}}">
            <i class="fa fa-list fs-2 pt-1 mb-2"><span class="path1"></span><span class="path2"></span><span
                class="path3"></span><span class="path4"></span></i>
        </button>
    </div>
    <div [id]="logsDestinationElemId" *ngIf="logsDestinationElemId">
        <button class="app-engage-btn btn btn-success stick-log-button" (click)="onShowLogButtonClick()" id="sticky-log-button">
            <span *ngIf="numberOfLogs" class="log-count">{{ numberOfLogs }}</span>
            <i [class.flaticon2-bell-2]="!!numberOfLogs" [class.flaticon2-bell-1]="!numberOfLogs"  class="fs-2"></i>
        </button>
    </div>
</div>
