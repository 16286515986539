import {
    Directive,
    Input,
    ComponentFactoryResolver,
    ViewContainerRef,
    Injector,
    SimpleChanges,
    OnChanges, ChangeDetectorRef,
} from '@angular/core';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';

@Directive({
    selector: '[busyIf]',
})
export class BusyIfDirective implements OnChanges {
    private static index = 0;

    @Input() loadingText = '';
    @Input() busyIf: boolean;
    @Input() debounceTime: number = 1000;

    ngxSpinnerService: NgxSpinnerService;
    isBusy = false;

    private spinnerName = '';


    constructor(
        private _viewContainer: ViewContainerRef,
        private _componentFactoryResolver: ComponentFactoryResolver,
        private _injector: Injector,
        private readonly cdr: ChangeDetectorRef
    ) {
        this.ngxSpinnerService = _injector.get(NgxSpinnerService);
        this.loadComponent();
    }


    refreshState(): void {
        if (this.isBusy === undefined || this.spinnerName === '') {
            return;
        }

        setTimeout(() => {
            if (this.isBusy) {
                this.ngxSpinnerService.show(this.spinnerName);
            } else {
                this.ngxSpinnerService.hide(this.spinnerName);
            }
            this.cdr.markForCheck();
        }, this.debounceTime);
    }

    loadComponent() {
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(NgxSpinnerComponent);

        const projectedContents = [];

        if (this.loadingText) {
            const text = document.createElement('p');
            text.textContent = this.loadingText;
            text.style.fontSize = '14px';
            text.style.color = '#fff';
            projectedContents.push([text]);
        }

        const componentRef = this._viewContainer.createComponent(componentFactory, this._viewContainer.length, undefined, projectedContents);
        this.spinnerName = 'busyIfSpinner-' + BusyIfDirective.index++ + '-' + Math.floor(Math.random() * 1000000); // generate random name
        let component = <NgxSpinnerComponent>componentRef.instance;
        component.name = this.spinnerName;
        component.fullScreen = false;

        component.type = 'ball-clip-rotate';
        component.size = 'medium';
        component.color = '#5ba7ea';
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.loadingText) {
            if (this.spinnerName) {
                this.ngxSpinnerService.hide(this.spinnerName);
            }

            this.loadComponent();
        }

        if (changes.busyIf) {
            this.isBusy = changes.busyIf.currentValue;
            this.refreshState();
        }
    }
}
