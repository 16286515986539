<label class="form-label"  [for]="Id">{{LabelToLocalize | localize}}</label>
<input bsDatepicker
       autocomplete="off"
       class="form-control"
       type="text"
       [disabled]="Disabled"
       [bsConfig]="bsConfig"
       (ngModelChange)="onDateChange($event)"
       [(ngModel)]="_selectedDate"
       [minDate]="MinDate"
       [maxDate]='MaxDate'
       [id]="Id"
       [name]="Id"
       [required]="IsRequired">
<button
    *ngIf="Clearable"
    (click)="onClearClicked()"
    class="btn btn-xs btn-outline-danger bs-datepicker-clear"
    title="Temizle">
    <i class="fas fa-ban"></i>
</button>
